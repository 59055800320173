<template>
  <div class="faq-container">
    <!-- Category List (Mobile) -->
    <div class="category-select" v-if="isMobile">
      <h3 class="category-title">Topic</h3>
      <div class="custom-dropdown">
        <div class="dropdown-trigger" @click="toggleDropdown">
          {{ selectedCategoryLabel }}
          <span class="dropdown-icon">{{ dropdownOpen ? '▲' : '▼' }}</span>
        </div>
        <div class="dropdown-options" v-if="dropdownOpen">
          <div
            class="dropdown-option"
            @click="selectCategory(null)"
          >
            All
          </div>
          <div
            v-for="category in categories"
            :key="category.slug"
            class="dropdown-option"
            @click="selectCategory(category.slug)"
          >
            {{ category.title }}
          </div>
        </div>
      </div>
    </div>

    <!-- Category List (Desktop) -->
    <div class="category-list" v-else-if="categories.length">
      <h3 class="category-title">Topic</h3>
      <div class="category-item">
        <a href="#" :class="{ active: !selectedCategory }" @click.prevent="selectCategory(null)">
          All
        </a>
      </div>
      <div v-for="category in categories" :key="category.slug" class="category-item">
        <a href="#" :class="{ active: selectedCategory === category.slug, 'selected-category': selectedCategory === category.slug }" @click.prevent="selectCategory(category.slug)">
          {{ category.title }}
        </a>
      </div>
    </div>

    <!-- FAQ List -->
    <div class="faq-list" v-if="faqs.length && !loading">
      <h3 class="faq-title">{{ selectedCategoryLabel }}</h3>
      <div v-for="(faq, index) in faqs" :key="faq.title" class="faq-item">
        <button class="accordion-button" @click="toggleFaq(index)" :class="{ 'selected-faq': faq.open }">
          <span>{{ faq.title }}</span>
          <span class="icon">
            <span v-if="!faq.open">+</span>
            <span v-else>x</span>
          </span>
        </button>

        <div v-show="faq.open" class="accordion-content">
          <div v-html="faq.body ?? faq.answer"></div>
        </div>
      </div>
    </div>
    <div v-else-if="!loading">
      No FAQs available for this category.
    </div>
    <div v-else>
      Loading FAQs...
    </div>
  </div>
</template>

<script>
import debug from "debug";
import axios from "axios";

let log = debug('component:Faqs');

export default {
  name: 'faqs',
  data() {
    return {
      faqs: [],
      categories: this.$attrs.categories || [],
      selectedCategory: null,
      loading: false,
      isMobile: window.innerWidth <= 768,
      dropdownOpen: false,
    };
  },
  computed: {
    selectedCategoryLabel() {
      if (!this.selectedCategory) {
        return 'All';
      }
      const selectedCategory = this.categories.find(category => category.slug === this.selectedCategory);
      return selectedCategory ? selectedCategory.title : 'All';
    }
  },
  mounted() {
    log('Mounted');
    window.addEventListener('resize', this.checkMobileView);

    // Load initial FAQs and check if URL has a category param
    if (this.$attrs.faqs) {
      this.faqs = this.$attrs.faqs.map(faq => ({
        ...faq,
        open: false,
      }));
    }

    const urlParams = new URLSearchParams(window.location.search);
    const categoryParam = urlParams.get('category');
    if (categoryParam) {
      this.selectedCategory = categoryParam;
      this.fetchFaqs();
    } else {
      this.fetchFaqs(); // Load all FAQs by default
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobileView);
  },
  methods: {
    toggleFaq(index) {
      this.faqs.forEach((faq, i) => {
        faq.open = i === index ? !faq.open : false;
      });
      this.$forceUpdate();
    },
    selectCategory(slug) {
      this.dropdownOpen = false;
      this.selectedCategory = slug;

      this.updateUrl(slug);
      this.fetchFaqs();
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    updateUrl(slug) {
      const url = slug ? `/faq?category=${slug}` : '/faq';
      history.pushState(null, '', url);
    },
    async fetchFaqs() {
      this.loading = true;

      try {
        const response = await axios.get('/api/faq.json', {
          params: {
            category: this.selectedCategory,
          },
        });

        // Handle the response with the 'data' key which contains the FAQs
        if (response.data && Array.isArray(response.data.data)) {
          this.faqs = response.data.data.map(faq => ({
            ...faq,
            open: false,
          }));
        } else {
          this.faqs = [];
        }
      } catch (error) {
        this.faqs = [];
      } finally {
        this.loading = false;
      }
    },
    checkMobileView() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
};
</script>

<style scoped>
.faq-container {
  display: flex;
  max-width: 1000px;
  margin: auto;
  flex-direction: column;
  padding: 0 1rem 3rem 1rem;
}

.category-list {
  padding: 0 1rem;
  text-align: left;
  min-width: 175px;
}

.selected-category {
  color: #DC6C34 !important;
}

.category-item {
  margin-bottom: 1rem;
}

.category-item a {
  text-decoration: none;
  color: #333;
}

.category-item a.active {
  text-decoration: underline;
  font-weight: bold;
}

.category-title {
  text-align: left;
  padding-bottom: 5px;
}

.category-select {
  margin: 1rem 0;
  position: relative;
}

.custom-dropdown {
  background: #fff;
  border: 2px solid #f1f1f1;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  position: relative;
}

.dropdown-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #53555C;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 2px solid #f1f1f1;
  border-top: none;
  border-radius: 0 0 8px 8px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-option {
  padding: 1rem;
  cursor: pointer;
}

.dropdown-option:hover {
  background: #f1f1f1;
}

.faq-title {
  text-align: left;
  padding-bottom: 10px;
  padding-left: 1rem;
}

.faq-list {
  padding: 2rem 1rem;
  border: 2px solid #f1f1f1;
  width: 100%;
}

.faq-item:not(:first-child) {
  border-top: 2px solid #f1f1f1;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  background: #fff;
  border: none;
  padding: 1rem;
  font-size: 1.2em;
  cursor: pointer;
}

.accordion-button:hover {
  color: #000;
}

.accordion-button .icon {
  font-size: 1.5rem;
}

.accordion-content {
  padding: 1rem;
  background: #f1f1f1;
  text-align: left;
}

@media (min-width: 769px) {
  .faq-container {
    flex-direction: row;
  }
  .category-select {
    display: none;
  }
}

@media (max-width: 768px) {
  .category-list {
    display: none;
  }
}
</style>
